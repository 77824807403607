import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Watch } from 'scrollmonitor-react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import GridLayout, { GridItem } from '../components/utils/GridLayout'
import Cta from '../components/utils/Cta'

import style from './style/manifesto.module.scss'

import { removeNewLine } from '../functions'
import { getTitleValue, getContentValue, MovingPlant } from './index'

/**
 * Images
 */
import essential from '../images/manifesto/essential.svg'
import food from '../images/manifesto/food-innovation.svg'
import human from '../images/manifesto/human.svg'
import protection from '../images/manifesto/protection.svg'

import { 
  plants, 
  redPlants, 
  greenPlant, 
  coralPlant,
  blueLeaf,
  blueCoral,
  seeds
} from '../plants/manifesto-plants'

const INITIAL_DELAY = 1500;

const GRID_LEFT = {
  col: { md: 5, lg: 5 },
  push: { md: 1, lg: 1 },
};

const GRID_RIGHT = {
  col: { md: 5, lg: 5 },
  push: { md: 6, lg: 6 },
};


/**
 * Manifest duple title / content
 * @param {String} options.title   [description]
 * @param {String} options.content [description]
 */
function Manifest({title, content}) {
  return (
    <div className = { style.Manifest }>
      <h3 className = 'title title--h4'>
        { title }
      </h3>
      <p className = 'crps'>
        { content }
      </p>
    </div>
  )
}

/**
 * Company value
 * @param {String} options.title   [description]
 * @param {String} options.content [description]
 * @param {String} options.img     [description]
 */
function Value({title, content, img}) {
  return (
    <div className = { style.Value }>
      <div className = { style.Value__img }>
        <img 
          src = { img }
          alt = { title } />
      </div>

      <div className = { style.Value__content }>
        <h3 className = 'crps'>
          { title }
        </h3>
        <p className = 'crps'>
          { content }
        </p>
      </div>
    </div>
  )
}

const WatchPlant = Watch(function(props) {
  return <MovingPlant { ...props } />
})

const WatchManifest = Watch(function(props) {
  return <Manifest { ...props } />
})

const ManifestoPage = () => {
  const { allMarkdownRemark: {edges} } = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(manifesto)/"}}) {
        edges {
          node {
            htmlAst
            fileAbsolutePath
            headings {
              depth
              value
            }
          }
        }
      }
    }
  `)

  const [animateRedPlants, setAnimateRedPlants] = useState(false);
  const [animateGreenPlants, setAnimateGreenPlants] = useState(false);
  const [animateCoral, setAnimateCoral] = useState(false);
  const [animateBlueCoral, setAnimateBlueCoral] = useState(false);
  const [animateSeeds, setAnimateSeeds] = useState(false);

  useEffect(() => {
    if ( !animateCoral ){
      setTimeout(() => (setAnimateCoral(true)),INITIAL_DELAY)
    }
  }, [])

  const { node: { htmlAst: { children } } } = edges[0];
  const pageData = children.filter(removeNewLine);

  const manifests = [{
    title: [getTitleValue(pageData[2]),<span>{ getTitleValue(pageData[2],2) }</span>],
    content: getContentValue(pageData[3]),
    grid: GRID_RIGHT,
  },{
    title: getTitleValue(pageData[4]),
    content: getContentValue(pageData[5]),
    grid: GRID_LEFT,
  },{
    title: getTitleValue(pageData[6]),
    content: getContentValue(pageData[7]),
    grid: GRID_RIGHT,
  },];

  const values = [{
    title: getTitleValue(pageData[11]),
    content: getContentValue(pageData[12]),
    img: human,
  },{
    title: getTitleValue(pageData[13]),
    content: getContentValue(pageData[14]),
    img: food,
  },{
    title: getTitleValue(pageData[15]),
    content: getContentValue(pageData[16]),
    img: protection,
  },{
    title: getTitleValue(pageData[17]),
    content: getContentValue(pageData[18]),
    img: essential,
  },];

  return (
    <Layout>
      <SEO title="Manifesto" />

      <div id = { style.Manifesto }>

        { redPlants.map((plant,key) => (
          <WatchPlant
            enterViewport = {(props) => {
              const { isInViewport } = props
              if ( isInViewport && !animateRedPlants )
                setAnimateRedPlants(true);
            }}
            className = { style.MovingPlant }
            imgClassName = { animateRedPlants ? `aos-manual` : '' }
            key = { key }
            { ...plant }/>
        )) }

        {
          coralPlant.map((plant, key) => {
            let className = style.MovingPlant;
            if (animateCoral)
              className += ' aos-manual';
            return (
              <WatchPlant
                className = { className }
                key = { key }
                { ...plant }/>
            )
          })
        }

        {
          blueLeaf.map((plant, key) => (
            <WatchPlant
              className = { style.MovingPlant }
              imgClassName = { animateBlueCoral ? `aos-manual` : '' }
              key = { key }
              { ...plant }/>
          ))
        }

        {
          blueCoral.map((plant, key) => (
            <WatchPlant
              className = { style.MovingPlant }
              imgClassName = { animateBlueCoral ? `aos-manual` : '' }
              key = { key }
              { ...plant }/>
          ))
        }
        {
          seeds.map((plant, key) => (
            <WatchPlant
              className = { style.MovingPlant }
              imgClassName = { animateSeeds ? `aos-manual` : '' }
              key = { key }
              { ...plant }/>
          ))
        }

        { 
          plants.map((plant,key) => (
            <MovingPlant
              className = { style.MovingPlant }
              key = { key }
              { ...plant }/>
          )) 
        }


        <WatchPlant
          fullyEnterViewport = {(props) => {
            const { isInViewport } = props
            if ( isInViewport && !animateGreenPlants )
              setAnimateGreenPlants(true);
          }}
          className = { style.MovingPlant }
          imgClassName = { animateGreenPlants ? `aos-manual` : '' }
          { ...greenPlant }/>

        <GridLayout>
          <GridItem grid = {{
            col: { md: 10, lg: 8 },
            push: { md: 1, lg: 2 },
          }}>
            <header>
              <h1 className = 'title title--h3'>
                { getTitleValue(pageData[0]) }
              </h1>
              <h2 className = 'title title--pages'>
                { getTitleValue(pageData[1]) }
              </h2>
            </header>
          </GridItem>
        </GridLayout>


        <ManifestPart { ...manifests[0] } />

        <WatchManifestPart 
          partiallyExitViewport = {({isAboveViewport}) => {
            if ( isAboveViewport && !animateSeeds)
              setAnimateSeeds(true);
          }}
          fullyEnterViewport = {({isFullyInViewport}) => {
            if ( isFullyInViewport && !animateBlueCoral)
              setAnimateBlueCoral(true);
          }}
          { ...manifests[1] } />

        <WatchManifestPart 
          fullyEnterViewport = {({isFullyInViewport}) => {
            console.log(isFullyInViewport)
            if ( isFullyInViewport && !animateSeeds)
              setAnimateSeeds(true);
          }}
          { ...manifests[2] } />


      </div>

      <div id = { style.United }>

        <div className = 'wrapper'>
          <h2 className = 'title title--h3'>
            { getTitleValue(pageData[10]) }
          </h2>
        </div>

        <div className = { style.ValuesWrapper }>
          <GridLayout className = 'grid-uniform'>
            { values.map((value, key) => (
              <GridItem 
                key = { key }
                grid = {{ col: { md: 6, lg: 6 } }}>
                <Value { ...value } />
              </GridItem>
            )) }
          </GridLayout>
        </div>

        <div className = { `wrapper ${style.missionCta}` }>
          <Cta 
            href = '/services'
            className = 'button button--white'>
            Our solutions
          </Cta>
        </div>

      </div>
      
    </Layout>
  )
}

export default ManifestoPage


function ManifestPart({grid, title, content}) {
  return (
    <div className = { style.ManifestWrapper }>
      <GridLayout>
        <GridItem grid = { grid }>
          <Manifest title = { title } content = { content } />
        </GridItem>
      </GridLayout>
    </div>
  )
}

function WatchManifestPart({grid, title, content, fullyEnterViewport, partiallyExitViewport}) {
  return (
    <div className = { style.ManifestWrapper }>
      <GridLayout>
        <GridItem grid = { grid }>
          <WatchManifest 
            title = { title } 
            content = { content } 
            partiallyExitViewport = { partiallyExitViewport }
            fullyEnterViewport = { fullyEnterViewport }/>
        </GridItem>
      </GridLayout>
    </div>
  )
}